<template>
  <b-card
    v-if="transactions"
    class="card-transaction"
    no-body
  >
    <b-row>
      <b-col>       
          <b-tabs>
            <b-tab
              title="Compras"
              active
            >
              <b-card-body>
                <div
                  v-for="order in orders"
                  :key="order.id"
                  class="transaction-item"
                >
                  <b-media no-body>
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="41"
                        :variant="`light-${transactionIconVariant(order.order_type)}`"
                      >
                        <feather-icon
                          size="18" 
                          :icon="transactionIcon(order.order_type)"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
            
                      <b-link
                        :to="{ name: 'order-view', params: { id: order.id } }"
                      >
                        <h6
                          class="transaction-title"
                        >
                          {{ OrderType(order.order_type) }}
                        </h6>
                      </b-link>
                      <b-badge
                        pill
                        :variant="`light-${paymentTypeVariant(order.order_payments_attributes[0].payment_type)}`"
                      >
                        {{ order.order_payments_attributes[0].payment_type | paymentType }}
                      </b-badge>

                      <b-badge
                        pill
                        :variant="`light-${paymentStatus(order.payment_status)}`"
                      >
                        {{ transactionName(order.payment_status) }}
                      </b-badge>
          
                      <small>
                        {{ order.created_at | date }}
                      </small>
                    </b-media-body>
                  </b-media>
                  <div
                  
                    class="font-weight-bolder"
                    :class="`text-${transactionIconVariant(order.payment_status)}`"
                  >
                    ${{ order.total | money }}
                  </div>
                </div>
              </b-card-body>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
                    <label># de resultados</label>
                    <v-select
                      v-model="entriesPerPage"
                      :dir="'ltr'"
                      :options="perPage"
                      :clearable="false"
                      class="per-page-selector d-inline-block ml-50 mr-1"
                    />
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    class="d-flex align-items-center justify-content-center pt-2"
                  >
          
                    <b-pagination
                      v-model="pagination.page"
                      :total-rows="pagination.total_objects"
                      :per-page="pagination.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                      @change="(value)=>{handlePagination({ page: value, per_page: pagination.per_page })}"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <b-spinner
                          v-if="loading"
                          small
                          label="Loading"
                        />
                        <feather-icon
                          v-else
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
          
                  </b-col>
          
                </b-row>
              </div>
            </b-tab>
            <b-tab title="Movimientos">
              <b-card-body>
                <div
                  v-for="transaction in transactions"
                  :key="transaction.id"
                  class="transaction-item"
                >
                  <b-media no-body>
                    <b-media-aside v-if="transaction.transactable && transaction.transactable.order_type">
                      <b-avatar
                        rounded
                        size="41"
                        :variant="`light-${transactionIconVariant(transaction.transaction_type)}`"
                      >
                        <feather-icon
                          size="18"
                          :icon="transactionIcon(transaction.transactable.order_type)"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6
                        v-if="transaction.transactable && transaction.transactable.auto_inc_folio && transaction.transactable.payment_status"
                        class="transaction-title"
                      >
                        {{ transactionName(transaction.transactable.payment_status) }}
                      </h6>
                      <h6
                        v-if="transaction.transactable && !transaction.transactable.auto_inc_folio && transaction.transaction_type === 'credit' && transaction.transactable.payment_status"
                        class="transaction-title"
                      >
                        {{ paymentName(transaction.transactable.payment_status) }}
                      </h6>
    
                      <h6
                        v-if="transaction.transactable && !transaction.transactable.auto_inc_folio && transaction.transaction_type === 'debit'"
                        class="transaction-title"
                      >
                        {{ 'Retiro de efectivo' }}
                      </h6>
                      
                      <b-badge
                        pill
                        :variant="`light-${paymentTypeVariant(transaction.account_type)}`"
                      >
                        {{ transaction.account_type | accountType }}
                      </b-badge>
                      <small >
                        {{ transaction.created_at | date }}
                      </small>
                    </b-media-body>
                  </b-media>
                  <div
                    v-if="transaction.transactable && transaction.transactable.payment_status"
                    class="font-weight-bolder"
                    :class="`text-${transactionIconVariant(transaction.transactable.payment_status)}`"
                  >
                    {{ transaction.transaction_type === 'credit' ? '+' : '-' }} ${{ transaction.transactable.amount ? transaction.transactable.amount : transaction.transactable.total | money }}
                  </div>
                </div>
              </b-card-body>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  > 
                    <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
                    <label># de resultados</label>
                    <v-select
                      v-model="entriesPerPage"
                      :dir="'ltr'"
                      :options="perPage"
                      :clearable="false"
                      class="per-page-selector d-inline-block ml-50 mr-1"
                    />
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    class="d-flex align-items-center justify-content-center pt-2"
                  >
          
                    <b-pagination
                      v-model="pagination.page"
                      :total-rows="pagination.total_objects"
                      :per-page="pagination.per_page"
                      first-number
                      last-number
                      class="mb-0"
                      prev-class="prev-item"
                      next-class="next-item"
                      @change="(value)=>{handlePagination({ page: value, per_page: pagination.per_page })}"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <b-spinner
                          v-if="loading"
                          small
                          label="Loading"
                        />
                        <feather-icon
                          v-else
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
          
                  </b-col>
          
                </b-row>
              </div>
            </b-tab>

            <b-tab title="Facturas">
              <b-row>
                <b-col>
                  <tax-invoices />
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
    
          <!--
            <b-dropdown
              variant="link"
              no-caret
              class="chart-dropdown"
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item href="#">
                Last 28 Days
              </b-dropdown-item>
              <b-dropdown-item href="#">
                Last Month
              </b-dropdown-item>
              <b-dropdown-item href="#">
                Last Year
              </b-dropdown-item>
            </b-dropdown>
          -->

      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
  BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import TaxInvoices from '@/views/invoices/InvoiceList.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BLink,
    vSelect,
    TaxInvoices,
  },

  data() {
    return {
      entriesPerPage: '10',
      perPage: ['10', '20', '50', '100'],
      loading: false,
    }
  },

  computed: {
    ...mapGetters('walleats', ['transactions']),
    ...mapGetters('orders', ['pagination','orders']),
  },

  watch: {
    entriesPerPage() {
      this.loading = true
      this.fetchTransactions({
        meta: {
          pagination: {
            page: this.pagination.page,
            per_page: this.entriesPerPage,
          },
        },
      })
        .then(response => {
          this.loading = false
        })
      this.fetchOrders({
        meta: {
          pagination: {
            page: this.pagination.page,
            per_page: this.entriesPerPage,
          },
        },
      })
        .then(response => {
          this.loading = false
        })
    },
  },

  beforeMount() {
    this.fetchTransactions()
    this.fetchOrders()
      .then(response => {
        this.loading = false
      })
  },


  methods: {
    ...mapActions('walleats', ['fetchTransactions']),
    ...mapActions('orders', ['fetchOrders']),

    handlePagination({ page, per_page }) {
      this.loading = true
      this.fetchTransactions({
        meta: {
          pagination: {
            page,
            per_page,
          },
        },
      })
      .then(response => {
            this.fetchOrders({
              meta: {
                pagination: {
                  page,
                  per_page,
                },
              },
            })
            .then(response => {
              this.loading = false
              this.allTransactions = this.transactions.concat(this.orders)
            })
          })
    },

    transactionIconVariant(status) {
      const statusList = {
        debit: 'warning',
        credit: 'success',
        sell: 'warning',
        emida: 'success',
        loan_payment: 'success'
      }
      return statusList[status] ? statusList[status] : 'primary'
    },
    paymentTypeVariant(status) {
      const statusList = {
        walleat: 'primary',
        cash: 'secondary',
        codi: 'secondary',
        reward_points: 'secondary',
        bankcard: 'secondary'
      }
      return statusList[status] ? statusList[status] : 'primary'
    },
    paymentStatus(status) {
      const statusList = {
        pending: 'secondary',
        payed: 'secondary',
        cancelled: 'secondary',
      }
      return statusList[status] ? statusList[status] : 'primary'
    },
    transactionIcon(status) {
      const statusList = {
        sell: 'ShoppingBagIcon',
        emida: 'ShoppingBagIcon',
        loan_payment: 'DollarSignIcon',
      }
      return statusList[status] ? statusList[status] : 'DollarSignIcon'
    },
    transactionName(status) {
      const statusList = {
        payed: 'Pago exitoso',
        pending: 'Pago pendiente',
        cancelled: 'Operación cancelada',
      }
      return statusList[status] ? statusList[status] : 'Error'
    },
    OrderType(status) {
      const statusList = {
        sell: 'Compra de productos',
        emida: 'Pago de servicios/compra de saldo',
        loan_payment: 'Abonaste a la deuda',
      }
      return statusList[status] ? statusList[status] : 'Error'
    },
    paymentName(status) {
      const statusList = {
        payed: 'Deposito exitoso',
        pending: 'Deposito pendiente',
        cancelled: 'Deposito cancelada',
      }
      return statusList[status] ? statusList[status] : 'Error'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.per-page-selector {
  width: 90px;
}
</style>
