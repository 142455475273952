<template>
  <div>
    <b-row>
      <b-col>
        <component :is="dashboardDependingRole(userData.role_name)" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AdminDashboard from "@/views/dashboard/AdminDashboard.vue"
import CustomerDashboard from "@/views/dashboard/CustomerDashboard.vue"
import StoreClerkDashboard from "@/views/dashboard/StoreClerkDashboard.vue"
import EstablishmentAdminDashboard from "@/views/dashboard/EstablishmentAdminDashboard.vue"

import { getUserData } from "@/auth/utils"

export default {
  components: {
    EstablishmentAdminDashboard,
    CustomerDashboard,
    StoreClerkDashboard,
    AdminDashboard,
  },
  data() {
    return {
      userData: {},
    }
  },
  created() {
    this.userData = getUserData()

    if (this.userData.role_name === "store_clerk") {
      this.$router.push({
        name: "pos-tiendas",
        params: { store_id: this.userData.role_resource_id },
      })
    }

    if (this.userData.role_name === "establishment_presale_clerk") {
      const resourceId = this.userData.scoped_roles.find(
        (role) => role.role_name === "establishment_presale_clerk"
      ).role_resource_id

      this.$router.push({
        name: "establishment-view",
        params: { id: resourceId },
      })
    }

    if (this.userData.role_name === "establishment_supplier") {
      const resourceId = this.userData.scoped_roles.find(
        (role) =>
          role.role_name === "establishment_admin" && role.role_resource_id
      ).role_resource_id

      this.$router.push({
        name: "establishment-view",
        params: { id: resourceId },
      })
    }
  },
  methods: {
    dashboardDependingRole(role) {
      const roles = {
        admin: "admin-dashboard",
        establishment_admin: "establishment-admin-dashboard",
        customer: "customer-dashboard",
        // store_clerk: "store-clerk-dashboard",
      }
      return roles[role]
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
